// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.App-header {
  background-color: #f8f8f8;
  height: 48px;
  width:100%;
  color: white;
  display: flex;
  justify-content: space-between;
  /* 垂直居中 */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX","sourcesContent":[".App {\n  text-align: center;\n  height: 100%;\n  width: 100%;\n  position: relative;\n}\n\n.App-header {\n  background-color: #f8f8f8;\n  height: 48px;\n  width:100%;\n  color: white;\n  display: flex;\n  justify-content: space-between;\n  /* 垂直居中 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
