import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";


function App() {
  const [currentTab, SetCurrentTab] = useState(0);
  const [videoId, SetVideoId] = useState("");
  const [leftTabStatus, SetLeftTabStatus] = useState(true);
  return (
    <div className="App">
      <header className="App-header">
        <img src={require("./assets/header/left.jpg")}/> 
        <img src={require("./assets/header/right.jpg")}/> 
      </header>
    </div>
  );
}

export default App;
